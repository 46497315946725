<template>
  <div class="ParkRefundForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :footerShow="false"
      @update="update"
    >
      <col2-detail>
        <col2-block title="订单信息">
          <col2-item
            label="订单编号"
            :text="detailItem.carParkApplicationV.serialNumber"
          />
          <col2-item
            label="订单状态"
            :text="
              applicationStatusMap[
                detailItem.carParkApplicationV.applicationStatus
              ]
            "
          />
          <col2-item label="服务商家" :text="detailItem.busName" />
          <col2-item label="下单类型" :text="'后台'" />
          <col2-item label="预约时间" text="/" />
          <col2-item
            label="退款时间"
            :text="detailItem.carParkApplicationV.intime"
          />
          <col2-item label="订单类型" :text="detailItem.orderTypeStr" />
          <col2-item label="退款方式" text="/" />
          <col2-item label="物流金额" text="¥0.00" />
          <col2-item label="退款状态" :text="'已支付'" />
          <col2-item
            label="实退金额"
            :text="`¥${detailItem.orderMoneyTotal}`"
          />
          <col2-item
            label="支付方式"
            :text="paymentChannelMap[detailItem.moneyType]"
          />
          <col2-item label="抵价票券" :text="detailItem.couponInfo" />
          <col2-item
            label="支付时间"
            :text="detailItem.payTime ? detailItem.payTime : '/'"
          />

          <col2-item label="实付金额" :text="`¥${detailItem.orderMoneyReal}`" />
        </col2-block>
        <col2-block title="下单人信息">
          <col2-item
            label="下单人姓名"
            :text="detailItem.orderUserInfoV.userName"
          />
          <col2-item
            label="下单人电话"
            :text="detailItem.orderUserInfoV.userPhone"
          />
          <col2-item
            label="下单人地址"
            :text="detailItem.orderUserInfoV.userAddress"
          />
        </col2-block>
        <col2-block title="订单内容">
          <div class="table-wrapper">
            <table-panel
              :headers="orderHeaders"
              :tableData="orderData"
              :hasOperateColumn="false"
            >
            </table-panel>
          </div>
          <col2-item label="订单描述" :text="detailItem.remark" />
        </col2-block>
        <col2-block title="发票信息" v-if="invoiceType === 1">
          <!-- 个人发票 -->
          <col2-item
            :span="24"
            label="抬头类型"
            :text="detailItem.invoiceInfoV.invoiceTypeStr"
          />
          <col2-item
            :span="24"
            label="发票类型"
            :text="detailItem.invoiceInfoV.invoiceTypeValue"
          />
          <col2-item
            :span="24"
            label="收票人姓名"
            :text="detailItem.invoiceInfoV.userName"
          />
          <col2-item
            :span="24"
            label="收票人手机"
            :text="detailItem.invoiceInfoV.userPhone"
          />
          <col2-item
            :span="24"
            label="收票人邮箱"
            :text="detailItem.invoiceInfoV.userEmail"
          />
        </col2-block>
        <col2-block title="发票信息" v-if="invoiceType === 2">
          <!-- 企业发票--增值税普票 -->
          <col2-item
            label="抬头类型"
            :text="detailItem.invoiceInfoV.invoiceTypeStr"
          />
          <col2-item
            label="发票抬头"
            :text="detailItem.invoiceInfoV.invoiceTitle"
          />
          <col2-item
            label="发票类型"
            :text="detailItem.invoiceInfoV.invoiceTypeValue"
          />
          <col2-item
            label="纳税人识别号"
            :text="detailItem.invoiceInfoV.taxpayerNumber"
          />
          <col2-item
            label="收票人手机"
            :text="detailItem.invoiceInfoV.userPhone"
          />
          <col2-item
            label="企业注册地址"
            :text="detailItem.invoiceInfoV.registeredAddress"
          />
          <col2-item
            label="收票人邮箱"
            :text="detailItem.invoiceInfoV.userEmail"
          />
          <col2-item
            label="企业注册电话"
            :text="detailItem.invoiceInfoV.registeredPhone"
          />
          <col2-item label="开票银行" :text="detailItem.invoiceInfoV.bank" />
          <col2-item
            label="银行账号"
            :text="detailItem.invoiceInfoV.bankAccount"
          />
        </col2-block>
        <col2-block title="发票信息" v-if="invoiceType === 3">
          <!-- 企业发票--增值税专票 -->
          <col2-item
            label="抬头类型"
            :text="detailItem.invoiceInfoV.invoiceTypeStr"
          />
          <col2-item
            label="发票抬头"
            :text="detailItem.invoiceInfoV.invoiceTitle"
          />
          <col2-item
            label="发票类型"
            :text="detailItem.invoiceInfoV.invoiceTypeValue"
          />
          <col2-item
            label="纳税人识别号"
            :text="detailItem.invoiceInfoV.taxpayerNumber"
          />
          <col2-item
            label="收票人姓名"
            :text="detailItem.invoiceInfoV.userName"
          />
          <col2-item
            label="企业注册地址"
            :text="detailItem.invoiceInfoV.registeredAddress"
          />
          <col2-item
            label="收票人手机"
            :text="detailItem.invoiceInfoV.userPhone"
          />
          <col2-item
            label="企业注册电话"
            :text="detailItem.invoiceInfoV.registeredPhone"
          />
          <col2-item
            label="收票人地址"
            :text="detailItem.invoiceInfoV.userAddress"
          />
          <col2-item label="开票银行" :text="detailItem.invoiceInfoV.bank" />
          <col2-item
            label="银行账号"
            :text="detailItem.invoiceInfoV.bankAccount"
          />
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getParkRefundDetail } from './api'
import {
  Col2Block,
  Col2Item,
  Col2Detail,
  TablePanel
} from 'components/bussiness'
import {
  payCategoryMap,
  suitTypeMap,
  rentTypeMap,
  sourceTypeMap,
  applicationStatusMap,
  formPayStatusMap,
  invoiceStatusMap
} from './map'
import { paymentChannelMap } from 'common/map'

export default {
  name: 'ParkRefundForm',
  components: {
    Col2Block,
    Col2Item,
    Col2Detail,
    TablePanel
  },
  data () {
    return {
      submitConfig: {},
      detailItem: {
        carParkApplicationV: {},
        orderUserInfoV: {},
        invoiceInfoV: {}
      },
      // 发票类型
      invoiceType: 0,
      suitTypeMap: suitTypeMap,
      rentTypeMap: rentTypeMap,
      sourceTypeMap: sourceTypeMap,
      applicationStatusMap: applicationStatusMap,
      formPayStatusMap: formPayStatusMap,
      invoiceStatusMap: invoiceStatusMap,
      paymentChannelMap: paymentChannelMap,
      orderHeaders: [
        {
          prop: 'cardNum',
          label: '提前终止车卡'
        },
        {
          prop: 'purchaseCount',
          label: '终止周期（单位：月）'
        },
        {
          prop: 'purchasePrice',
          label: '月均退款（租赁费/管理费）（单位：元）'
        },
        {
          prop: 'orderMoneyTotal',
          label: '总退款（租赁费/管理费）（单位：元）'
        }
      ],
      orderData: []
    }
  },

  created () {
    const { id } = this.$route.query
    this.submitConfig = {
      queryUrl: `${getParkRefundDetail}/${id}`
    }
  },

  mounted () {
    this.$refs.formPanel.getData()
    this.$setBreadcrumb('订单详情')
  },

  methods: {
    update (data) {
      let detailItem = Object.assign({}, data)

      // 优惠券
      let couponInfo = `使用${detailItem.discountNum}张，总共抵价¥${detailItem.orderMoneyDiscount}`
      detailItem.couponInfo = couponInfo

      let orderInfo = detailItem.carParkApplicationV
      if (orderInfo) {
        // 订单类型
        let orgName = orderInfo.orgName ? `（${orderInfo.orgName}）` : ''
        detailItem.orderTypeStr = `${
          payCategoryMap[orderInfo.payCategory]
        }${orgName}`

        // 订单信息的处理
        let order = {
          cardNum: orderInfo.cardNum,
          purchasePrice: `${orderInfo.purchasePrice}元`,
          purchaseCount: `${orderInfo.purchaseCount}个`,
          orderMoneyTotal: detailItem.orderMoneyTotal
        }
        this.orderData = [order]
      }

      // 发票信息
      let invoiceInfo = detailItem.invoiceInfoV
      if (invoiceInfo) {
        let invoiceType = invoiceInfo.invoiceType || 0
        this.invoiceType = invoiceType
        invoiceInfo.invoiceTypeStr = invoiceType === 1 ? '个人' : '企业'
        invoiceInfo.invoiceTypeValue =
          invoiceType === 3 ? '增值税专票' : '增值税普票'
        detailItem.invoiceInfoV = invoiceInfo
      }

      this.detailItem = detailItem
    }
  }
}
</script>

<style lang="scss" scoped>
.parkOrderManageForm-wrapper {
  .table-wrapper {
    width: 100%;
    margin-left: 85px;
  }
}
</style>
