var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ParkRefundForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { footerShow: false },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "订单信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      label: "订单编号",
                      text: _vm.detailItem.carParkApplicationV.serialNumber,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "订单状态",
                      text: _vm.applicationStatusMap[
                        _vm.detailItem.carParkApplicationV.applicationStatus
                      ],
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "服务商家", text: _vm.detailItem.busName },
                  }),
                  _c("col2-item", {
                    attrs: { label: "下单类型", text: "后台" },
                  }),
                  _c("col2-item", { attrs: { label: "预约时间", text: "/" } }),
                  _c("col2-item", {
                    attrs: {
                      label: "退款时间",
                      text: _vm.detailItem.carParkApplicationV.intime,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "订单类型",
                      text: _vm.detailItem.orderTypeStr,
                    },
                  }),
                  _c("col2-item", { attrs: { label: "退款方式", text: "/" } }),
                  _c("col2-item", {
                    attrs: { label: "物流金额", text: "¥0.00" },
                  }),
                  _c("col2-item", {
                    attrs: { label: "退款状态", text: "已支付" },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "实退金额",
                      text: `¥${_vm.detailItem.orderMoneyTotal}`,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "支付方式",
                      text: _vm.paymentChannelMap[_vm.detailItem.moneyType],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "抵价票券",
                      text: _vm.detailItem.couponInfo,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "支付时间",
                      text: _vm.detailItem.payTime
                        ? _vm.detailItem.payTime
                        : "/",
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "实付金额",
                      text: `¥${_vm.detailItem.orderMoneyReal}`,
                    },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "下单人信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      label: "下单人姓名",
                      text: _vm.detailItem.orderUserInfoV.userName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "下单人电话",
                      text: _vm.detailItem.orderUserInfoV.userPhone,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "下单人地址",
                      text: _vm.detailItem.orderUserInfoV.userAddress,
                    },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "订单内容" } },
                [
                  _c(
                    "div",
                    { staticClass: "table-wrapper" },
                    [
                      _c("table-panel", {
                        attrs: {
                          headers: _vm.orderHeaders,
                          tableData: _vm.orderData,
                          hasOperateColumn: false,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("col2-item", {
                    attrs: { label: "订单描述", text: _vm.detailItem.remark },
                  }),
                ],
                1
              ),
              _vm.invoiceType === 1
                ? _c(
                    "col2-block",
                    { attrs: { title: "发票信息" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "抬头类型",
                          text: _vm.detailItem.invoiceInfoV.invoiceTypeStr,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "发票类型",
                          text: _vm.detailItem.invoiceInfoV.invoiceTypeValue,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "收票人姓名",
                          text: _vm.detailItem.invoiceInfoV.userName,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "收票人手机",
                          text: _vm.detailItem.invoiceInfoV.userPhone,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "收票人邮箱",
                          text: _vm.detailItem.invoiceInfoV.userEmail,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.invoiceType === 2
                ? _c(
                    "col2-block",
                    { attrs: { title: "发票信息" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          label: "抬头类型",
                          text: _vm.detailItem.invoiceInfoV.invoiceTypeStr,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "发票抬头",
                          text: _vm.detailItem.invoiceInfoV.invoiceTitle,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "发票类型",
                          text: _vm.detailItem.invoiceInfoV.invoiceTypeValue,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "纳税人识别号",
                          text: _vm.detailItem.invoiceInfoV.taxpayerNumber,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "收票人手机",
                          text: _vm.detailItem.invoiceInfoV.userPhone,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "企业注册地址",
                          text: _vm.detailItem.invoiceInfoV.registeredAddress,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "收票人邮箱",
                          text: _vm.detailItem.invoiceInfoV.userEmail,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "企业注册电话",
                          text: _vm.detailItem.invoiceInfoV.registeredPhone,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "开票银行",
                          text: _vm.detailItem.invoiceInfoV.bank,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "银行账号",
                          text: _vm.detailItem.invoiceInfoV.bankAccount,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.invoiceType === 3
                ? _c(
                    "col2-block",
                    { attrs: { title: "发票信息" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          label: "抬头类型",
                          text: _vm.detailItem.invoiceInfoV.invoiceTypeStr,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "发票抬头",
                          text: _vm.detailItem.invoiceInfoV.invoiceTitle,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "发票类型",
                          text: _vm.detailItem.invoiceInfoV.invoiceTypeValue,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "纳税人识别号",
                          text: _vm.detailItem.invoiceInfoV.taxpayerNumber,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "收票人姓名",
                          text: _vm.detailItem.invoiceInfoV.userName,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "企业注册地址",
                          text: _vm.detailItem.invoiceInfoV.registeredAddress,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "收票人手机",
                          text: _vm.detailItem.invoiceInfoV.userPhone,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "企业注册电话",
                          text: _vm.detailItem.invoiceInfoV.registeredPhone,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "收票人地址",
                          text: _vm.detailItem.invoiceInfoV.userAddress,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "开票银行",
                          text: _vm.detailItem.invoiceInfoV.bank,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "银行账号",
                          text: _vm.detailItem.invoiceInfoV.bankAccount,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }