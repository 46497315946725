import { mapHelper } from 'common/utils'

// 支付渠道
const paymentChannel = [
  {
    text: '线下支付',
    value: 0
  },
  {
    text: '支付宝支付',
    value: 1
  },
  {
    text: '银联支付',
    value: 2
  },
  {
    text: '农行微信支付',
    value: 3
  },
  {
    text: '招行一网通',
    value: 4
  },
  {
    text: '余额支付',
    value: 5
  },
  {
    text: '通联支付',
    value: 6
  },
  {
    text: '在线支付',
    value: 9
  },
  {
    text: 'POS机支付',
    value: 11
  },
  {
    text: '微信支付',
    value: 12
  },
  {
    text: '微信公众号支付',
    value: 13
  },
  {
    text: '微信H5支付',
    value: 14
  },
  {
    text: '对公转账',
    value: 15
  },
  {
    text: '光大银行支付',
    value: 25
  },
  {
    text: '转结支付',
    value: 101
  },
]

const {
  map: paymentChannelMap,
  setOps: setPaymentChannelOps
} = mapHelper.setMap(paymentChannel)

export {
  paymentChannelMap,
  setPaymentChannelOps
}
